import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Dialog, Icon } from '@mui/material';
import { FirebaseError } from 'firebase/app';
import {
  AuthProvider,
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  fetchSignInMethodsForEmail,
  GoogleAuthProvider,
  linkWithCredential,
  OAuthCredential,
  OAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  UserCredential,
} from 'firebase/auth';

import ButtonComponent from '@/components/utility/microcomponents/Button';
import useAccountContext from '@/hooks/context/useAccountContext';
import useSnackbarContext from '@/hooks/context/useSnackbarContext';

import Loading from '../components/utility/Loading';
import { authErrors } from '../constants/FirebaseErrors';
import { auth } from '../firebaseSetup';

const LoginPage = ({ isSignUp }: { isSignUp?: boolean }) => {
  const { t } = useTranslation();

  const { dispatchFirebaseAccountId } = useAccountContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const isLogin = isSignUp ? false : true;
  const { dispatchSnackbar } = useSnackbarContext();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [authProvider, setAuthProvider] = useState<AuthProvider>();
  const [authCredential, setAuthCredential] = useState<OAuthCredential>();

  const [signInAgainButton, setSignInAgainButton] = useState<boolean>(false);

  const errors = authErrors;

  const navigate = useNavigate();

  const googleProvider = new GoogleAuthProvider();
  const facebookProvider = new FacebookAuthProvider();
  const appleProvider = new OAuthProvider('apple.com');

  const handleSignInSuccess = async (result: UserCredential) => {
    const user = result.user;

    if (user && !user.emailVerified) {
      navigate('/email-verification');
      return;
    }

    if (user) {
      await dispatchFirebaseAccountId(user.uid);
      navigate('/home');
    }
  };

  const handleSignInError = (error: FirebaseError) => {
    if (error.code === 'auth/account-exists-with-different-credential') {
      const credential = OAuthProvider.credentialFromError(error);
      if (credential) {
        setAuthCredential(credential);
        const email = error.customData?.email as string;
        fetchSignInMethodsForEmail(auth, email).then(() => {
          const provider = new GoogleAuthProvider();
          provider.setCustomParameters({
            login_hint: email,
          });
          setAuthProvider(provider);
          setSignInAgainButton(true);
        });
      }
    } else if (error.code) {
      processError(error);
    }

    setIsLoading(false);
  };

  const popUpSignIn = () => {
    if (authProvider && authCredential) {
      signInWithPopup(auth, authProvider).then(async (resp) => {
        await linkWithCredential(resp.user, authCredential);
        handleSignInSuccess(resp);
      });
    }
  };

  const emailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const passwordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const toggleLogin = () => {
    isLogin ? navigate('/sign-up') : navigate('/login');
  };

  const signIn = () => {
    setIsLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then(async (resp) => {
        if (!resp.user.emailVerified) {
          sendEmailVerification(resp.user, { url: window.location.origin });
          navigate('/email-verification');
          return;
        }
        handleSignInSuccess(resp);
        setIsLoading(false);
      })
      .catch((err) => {
        processError(err);
        setIsLoading(false);
      });
  };

  const signInWithGoogle = () => {
    setIsLoading(true);
    signInWithPopup(auth, googleProvider)
      .then(async (resp) => {
        await handleSignInSuccess(resp);
      })
      .catch((err) => {
        handleSignInError(err);
      });
  };

  const signInWithFacebook = () => {
    setIsLoading(true);
    signInWithPopup(auth, facebookProvider)
      .then(async (resp) => {
        await handleSignInSuccess(resp);
      })
      .catch((err) => {
        handleSignInError(err);
      });
  };

  const signInWithApple = () => {
    setIsLoading(true);
    signInWithPopup(auth, appleProvider)
      .then(async (resp) => {
        await handleSignInSuccess(resp);
      })
      .catch((err) => {
        handleSignInError(err);
      });
  };

  const createNewUser = () => {
    setIsLoading(true);
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (resp) => {
        await handleSignInSuccess(resp);
      })
      .catch((err) => {
        handleSignInError(err);
        setIsLoading(false);
      });
  };

  const processError = (error: FirebaseError) => {
    const code = error.code?.replace('auth/', '');
    dispatchSnackbar({
      type: 'OPEN_SNACKBAR',
      payload: {
        message: errors[code],
        type: 'error',
      },
    });
  };

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  const forgotPassword = () => {
    sendPasswordResetEmail(auth, email, {
      url: window.location.origin,
    })
      .then(() => {
        dispatchSnackbar({
          type: 'OPEN_SNACKBAR',
          payload: {
            message: 'Password reset email sent',
            type: 'success',
          },
        });
      })
      .catch(() => {
        dispatchSnackbar({
          type: 'OPEN_SNACKBAR',
          payload: {
            message: 'Please enter a valid email address',
            type: 'error',
          },
        });
      });
  };

  return (
    <div className="login-page" data-testid="login-page">
      <div className="container">
        <div className="login-header">
          <img src="/images/logos/full-text-logo.svg" alt="logo"></img>
          <p className="mt16">{t('LOGIN-PAGE.WEB-APP')}</p>
        </div>
        <div className="form-container">
          <h3 className="mb8">{isLogin ? t('LOGIN-PAGE.WELCOME-BACK') : t('LOGIN-PAGE.HI-THERE')}</h3>
          {isLoading && (
            <div className="pos-abs t0 r0">
              <Loading size="small" noText={true} />
            </div>
          )}
          <p>{isLogin ? t('LOGIN-PAGE.LOGIN-TO-YOUR-UNHURD-ACCOUNT') : t('LOGIN-PAGE.LETS-GET-YOU-SET-UP')}</p>
          <form>
            <label>
              <p className="mt32">{t('COMMON.EMAIL-ADDRESS')}</p>
              <input
                placeholder={t('COMMON.ENTER-EMAIL-ADDRESS')}
                name="email"
                value={email}
                disabled={isLoading}
                onChange={emailChange}
              />
            </label>
            <label>
              <div className="d-flex mt16 jc-space-between">
                <p>{t('COMMON.PASSWORD')}</p>
                {isLogin && (
                  <ButtonComponent isCustom onClick={forgotPassword}>
                    <p className="text-blue-gradient cursor-pointer">{t('COMMON.FORGOT-PASSWORD')}</p>
                  </ButtonComponent>
                )}
              </div>
              <input
                placeholder={t('COMMON.ENTER-PASSWORD')}
                type={visible ? 'text' : 'password'}
                autoComplete="on"
                disabled={isLoading}
                value={password}
                onChange={passwordChange}
              />
              <Button className="icon-btn input-visibility-icon" onClick={toggleVisibility}>
                {visible ? <Icon className="">visibility_off</Icon> : <Icon className="">visibility</Icon>}
              </Button>
            </label>
          </form>
          {isLogin && (
            <Button className="border-btn ml0 w100p mt-48" onClick={signIn} disabled={isLoading}>
              {t('COMMON.LOGIN')}
            </Button>
          )}
          {!isLogin && (
            <Button className="border-btn ml0 w100p mt-48" onClick={createNewUser} disabled={isLoading}>
              {t('COMMON.CREATE-MY-ACCOUNT')}
            </Button>
          )}
          <div className="d-flex form-divider">
            <div className="line"></div>
            <p className="small ml8 mr8">{t('COMMON.OR')}</p>
            <div className="line"></div>
          </div>
          <Button className="btn-white ml0 w100p mt16" disabled={isLoading} onClick={signInWithGoogle}>
            <Icon className="pr8">
              <img src="/images/logos/google-logo.svg" alt="" />
            </Icon>
            {t('LOGIN-PAGE.CONTINUE-WITH-GOOGLE')}
          </Button>
          <Button className="btn-white ml0 w100p mt16" disabled={isLoading} onClick={signInWithFacebook}>
            <Icon className="pr8">
              <img src="/images/logos/facebook-logo.svg" alt="" />
            </Icon>
            {t('LOGIN-PAGE.LOGIN-WITH-FACEBOOK')}
          </Button>
          <Button className="btn-white ml0 w100p mt16" disabled={isLoading} onClick={signInWithApple}>
            <Icon className="pr8">
              <img src="/images/logos/apple-logo.svg" alt="" />
            </Icon>
            {t('LOGIN-PAGE.CONTINUE-WITH-APPLE')}
          </Button>

          <Dialog open={signInAgainButton}>
            <div className="min-w400 max-w400">
              <h4>{t('LOGIN-PAGE.ACCOUNT-EXISTS-WITH-DIFFERENT-CREDENTIAL-DIALOG')}</h4>
              <div className="d-flex">
                <Button
                  className="ml0 w100p mt16"
                  disabled={isLoading}
                  onClick={() => {
                    setSignInAgainButton(false);
                  }}
                >
                  {t('COMMON.CLOSE')}
                </Button>
                <Button className="btn-white ml0 w100p mt16" disabled={isLoading} onClick={popUpSignIn}>
                  {t('COMMON.CONTINUE')}
                </Button>
              </div>
            </div>
          </Dialog>

          <p className="text-center small">
            {isLogin ? t('LOGIN-PAGE.DONT-HAVE-ACCOUNT') : t('LOGIN-PAGE.ALREADY-HAVE-ACCOUNT')}{' '}
            <ButtonComponent isCustom onClick={toggleLogin}>
              <span className="text-blue-gradient cursor-pointer">
                {isLogin ? t('COMMON.SIGN-UP') : t('COMMON.LOGIN')}
              </span>
            </ButtonComponent>
          </p>
        </div>
        <div className="login-footer">
          <a
            href="https://www.unhurd.co.uk/terms-conditions"
            target="_blank"
            rel="noreferrer"
            className="text-right text-blue w50p fs-sm pr16 cursor-pointer"
          >
            {t('COMMON.TERMS-AND-CONDITIONS')}
          </a>
          <a
            href="https://www.unhurd.co.uk/privacy-policy"
            target="_blank"
            rel="noreferrer"
            className="w50p text-blue fs-sm cursor-pointer"
          >
            {t('COMMON.PRIVACY-POLICY')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
